import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import { Container, Text, Image, Icon } from "@atoms";
import { ArchFrame, IntentionsProfileArtwork } from "@molecules";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const IntentionProfile = ({
  fullName,
  headshot,
  bio,
  artwork,
  socials,
  link,
  align,
}) => {
  const lang = useLang();

  return (
    <Container
      className={classNames(
        "relative my-24 flex min-h-[36rem] flex-col justify-center gap-4 py-10 text-white sm:justify-around sm:gap-16 sm:py-12 md:my-40 md:min-h-[46rem] lg:min-h-[62.8rem] xl:py-40",
        {
          "sm:flex-row-reverse": align === "right",
          "sm:flex-row": align === "left",
        }
      )}
    >
      {artwork?.map((art, i) => (
        <IntentionsProfileArtwork key={art.url} art={art} i={i} align={align} />
      ))}
      <div className="relative z-10 -mt-[15%] sm:mt-[10%] md:mt-[24%]">
        <Text variant="h2">{fullName}</Text>
        <Text className="prose mb-6 mt-2 inline-block max-w-md text-sm prose-p:break-inside-avoid">
          {bio}
        </Text>
        {link?.url && (
          <span className="mb-4 flex flex-col flex-wrap gap-1 text-lg font-bold md:inline-flex md:flex-row">
            {t("See more of their work at: ", lang)}
            <a
              className="underline transition duration-300 hover:opacity-70"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.text}
            </a>
          </span>
        )}

        {/* socials */}
        <div className="flex flex-wrap gap-4">
          {socials?.map(social => {
            const { url, platform } = social;
            const handle = url?.replace(/\/$/, "")?.split("/")?.pop();

            return (
              <AppLink
                to={url}
                className="flex items-center gap-1 transition duration-300 hover:opacity-70"
                key={url}
              >
                <Icon
                  name={platform}
                  className="duration-400 h-3 w-4 transition"
                  fitHeight={platform === "facebook"}
                />
                <span className="text-lg font-bold">{handle}</span>
              </AppLink>
            );
          })}
        </div>
      </div>
      {headshot && (
        <ArchFrame className="relative hidden w-full max-w-[10rem] text-white sm:block sm:max-w-[14rem] md:mt-[16%] lg:max-w-xs">
          <Image image={headshot} fill />
        </ArchFrame>
      )}
    </Container>
  );
};

IntentionProfile.defaultProps = {};

export default IntentionProfile;
